import { Coin } from '../client/station-clients/dymension/generated/cosmos/base/v1beta1/coin';
import { App } from '../client/station-clients/dymension/generated/rollapp/app';
import { Currency } from '../currency/currency-types';
import { HubAnalyticsSummary } from '../hub/statistics/hub-statistics-types';
import { RollappAnalyticsSummary } from '../rollapp/rollapp-statistics-types';
import { RollAppTag } from '../rollapp/rollapp-types';

export const EVM_COIN_TYPE = 60;

export type IbcDirection = 'Source' | 'Destination';

export type EvmType = 'EVM' | 'WASM';

export type NetworkType = 'Hub' | 'RollApp' | 'Regular' | 'EVM' | 'Solana';

export const ROLLAPP_STATUSES = [ 'Active', 'Degraded', 'IRO', 'Unavailable', 'Pre-Connection', 'Pre-Launch', 'Registered' ] as const;
export type RollappStatus = typeof ROLLAPP_STATUSES[number];

export const ROLLAPP_STATUS_INFO_MAP: { [status in RollappStatus]: { infoText: string, name: string, variableColor: string } } = {
    'Active': { infoText: 'RollApp is fully functional and connected to Dymension.', variableColor: '--light-green-rgb', name: 'Live' },
    'Degraded': {
        infoText: 'RollApp is not updating its state to Dymension, affecting finalization time.',
        variableColor: '--dark-orange-rgb',
        name: 'Degraded',
    },
    'Unavailable': { infoText: ' RollApp’s RPC endpoints are not accessible.', variableColor: '--red-rgb', name: 'Unavailable' },
    'IRO': { infoText: 'Token early sale for this RollApp is currently open.', variableColor: '--gold-rgb', name: 'Launchpad' },
    'Pre-Connection': {
        infoText: 'RollApp deployed but pending IBC connection to Dymension Hub.',
        variableColor: '--orange-rgb',
        name: 'Pre-Connection',
    },
    'Pre-Launch': {
        infoText: 'All launch conditions met. RollApp is ready to go live at any moment.',
        variableColor: '--orange-rgb',
        name: 'Pre-Launch',
    },
    'Registered': {
        infoText: 'RollApp is registered on Dymension but requires additional setup before launch.',
        variableColor: '--cream-dark-rgb',
        name: 'Registered',
    },
};

export const DATA_AVAILABILITY_CHAINS = [ 'BNB', 'Celestia', 'Avail', 'Walrus', 'Load Network', 'Sui', 'APTOS' ] as const;
export type DataAvailability = typeof DATA_AVAILABILITY_CHAINS[number];

type DataLayerInfo = {
    logo: string;
    fullLogo: string;
    id: string;
    costApiId?: string;
    cost?: string;
    throughput?: string;
    finalityTime?: string;
    dataRetention?: string;
}

export const DA_INFO_MAP: { [da in DataAvailability]: DataLayerInfo } = {
    BNB: {
        logo: require('../../assets/logos/bnb-logo.svg').default,
        fullLogo: require('../../assets/logos/bnb-logo-full.svg').default,
        id: 'bnb',
        throughput: '2.2 MBps',
        finalityTime: '7.5 sec',
        cost: '0.2 BNB per MB',
        dataRetention: 'permanent',
    },
    Celestia: {
        logo: require('../../assets/logos/celestia-logo-mask.png'),
        fullLogo: require('../../assets/logos/celestia-logo-white.png'),
        id: 'celestia',
        costApiId: 'celestia',
        throughput: '1.33 MBps',
        finalityTime: '6 sec',
        dataRetention: '30 days',
    },
    Avail: {
        logo: require('../../assets/logos/avail-logo.png'),
        fullLogo: require('../../assets/logos/avail-logo-full.svg').default,
        id: 'avail',
        costApiId: 'avail',
        throughput: '0.2 MBps',
        finalityTime: '40 sec',
        dataRetention: 'permanent',
    },
    Walrus: {
        logo: require('../../assets/logos/walrus-logo.svg').default,
        fullLogo: require('../../assets/logos/walrus-logo-full.svg').default,
        id: 'walrus',
        throughput: '>1 GBps',
        finalityTime: '<1 sec',
        cost: '$0.053 per MB',
        dataRetention: 'permanent',
    },
    'Load Network': {
        logo: require('../../assets/logos/weavevm-logo.svg').default,
        fullLogo: require('../../assets/logos/weavevm-logo-full.svg').default,
        id: 'loadnetwork',
        costApiId: 'babe',
        throughput: '250 MBps',
        finalityTime: '1 sec',
        dataRetention: 'permanent',
    },
    Sui: {
        logo: require('../../assets/logos/sui-logo.svg').default,
        fullLogo: require('../../assets/logos/sui-logo-full.svg').default,
        id: 'sui',
        cost: '2.797 SUI per MB',
        throughput: '297 MBps',
        finalityTime: '0.48 sec',
        dataRetention: 'permanent',
    },
    APTOS: {
        logo: require('../../assets/logos/aptos-logo-mask.svg').default,
        fullLogo: require('../../assets/logos/aptos-logo-full.svg').default,
        id: 'aptos',
        cost: '1 APT per MB',
        throughput: '160 MBps',
        finalityTime: '0.9 sec',
        dataRetention: 'permanent',
    },
};

export interface IbcConfig {
    hubChannel?: string;
    channel?: string;
    timeout: number;
    allowedDenoms?: string[];
}

export interface EvmConfig {
    chainId: string;
    rpc?: string;
}

export interface Sequencer {
    address: string;
    tokens: Coin[];
    moniker?: string;
    details?: string;
    explorerUrl?: string;
    website?: string;
    telegram?: string;
    x?: string;
}

export interface Network extends RollappAnalyticsSummary, HubAnalyticsSummary { // todo: find better way
    chainId: string;
    chainName: string;
    rpc?: string;
    rest?: string;
    bech32Prefix?: string;
    initialSequencer?: string;
    currencies: Currency[];
    gasPrice?: string;
    gasPriceSteps?: { low: number, average: number, high: number },
    gasAdjustment?: number;
    coinType: number;
    explorerUrl?: string;
    exploreTxUrl?: string;
    faucetUrl?: string;
    website?: string;
    logo: string;
    disabled?: boolean;
    initialSupply?: number;
    ibc?: IbcConfig;
    evm?: EvmConfig;
    type: NetworkType;
    da?: DataAvailability;
    drs?: number;
    evmType?: EvmType;
    tags?: RollAppTag[];
    common?: boolean;
    hidden?: boolean;
    apps?: App[];
    description?: string;
    shortDescription?: string;
    collectData?: boolean;
    cctp?: boolean;
    xAccount?: string;
    tgAccount?: string;
    owner?: string;
    sealed?: boolean;
    launched?: boolean;
    preLaunchTime?: number;
    genesisChecksum?: string;
    genesisUrl?: string;
    fairLaunch?: boolean;
    status?: RollappStatus;
    inactiveTime?: number;
    tokenless?: boolean;
    lastStateUpdateHeight?: number;
    sequencer?: Sequencer;
    latestFinalizedHeight?: number;
    partial?: boolean;
    creationDate?: number;
    minSequencerBond?: Coin[];
}

export type ChannelNetworkMap = { [channel: string]: Network };

export interface VirtualFrontierContract {
    contractAddress: string;
    minDenom: string;
    enabled: boolean;
}

export interface EibcRollappLiquidity {
    rollappId: string;
    liquidity: { [denom: string]: number };
}



