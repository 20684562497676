import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './da-selector-dialog.scss';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../../shared/components/button/button';
import Dialog, { DialogAction, DialogContent, DialogProps, DialogTitle } from '../../../../../shared/components/dialog/dialog';
import Spinner from '../../../../../shared/components/spinner/spinner';
import { formatPrice } from '../../../../../shared/utils/number-utils';
import { DA_INFO_MAP, DATA_AVAILABILITY_CHAINS, DataAvailability } from '../../../../network/network-types';

const DaSelectorDialog: React.FC<DialogProps> = (props) => {
    const navigate = useNavigate();
    const [ selectedDa, setSelectedDa ] = useState<DataAvailability>();
    const [ daCostMap, setDaCostMap ] = useState<{ [da in DataAvailability]?: string }>({});

    useEffect(() => {
        DATA_AVAILABILITY_CHAINS.forEach((da) => {
            const { cost, costApiId } = DA_INFO_MAP[da];
            const costPromise = !costApiId ? Promise.resolve(cost) :
                fetch(`${process.env.REACT_APP_DA_COST_BASE_URL}/v1/${costApiId}/1000000`)
                    .then((response) => response.json()).then((result) => Number(result['cost_in_usd']));
            costPromise
                .then((result) => setDaCostMap((daCostMap) => ({
                    ...daCostMap, [da]: !result ? '-' : typeof result !== 'number' ? result :
                        `${formatPrice(result, undefined, { maximumSignificantDigits: 2 })} per MB`,
                })))
                .catch((error) => {
                    console.error(`Can't fetch da cost`, error);
                    setDaCostMap((daCostMap) => ({ ...daCostMap, [da]: '-' }));
                });
        });
    }, []);

    const sortedDas = useMemo(() => [ ...DATA_AVAILABILITY_CHAINS ].sort((da1, da2) => da1.localeCompare(da2)), []);

    const onContinue = useCallback(() => {
        if (selectedDa) {
            localStorage.setItem('da-', JSON.stringify(selectedDa));
            navigate('/rollapps/manage/create');
        }
    }, [ navigate, selectedDa ]);

    return (
        <Dialog className='da-selector-dialog' {...props}>
            <DialogTitle className='dialog-title'>Launch On</DialogTitle>
            <DialogContent className='dialog-content'>
                <p className='dialog-subtitle'>
                    The chosen layer will serve as the source of truth for verifiability and validity of your chain
                    <span className='estimations-label'>* All data are estimated and subject to change</span>
                </p>
                <div className='das-grid'>
                    {sortedDas.map((da) => (
                        <Button
                            buttonType='secondary'
                            className='da-button'
                            key={da}
                            onClick={() => setSelectedDa(da === selectedDa ? undefined : da)}
                            active={da === selectedDa}
                        >
                            <img alt='da logo' className='da-logo' src={DA_INFO_MAP[da].logo} />
                            <p className='da-name'>{da}</p>

                            <div className='da-properties'>
                                <div className='da-property'>
                                    <span className='property-name'>Cost</span>
                                    <span className='property-value'>{daCostMap[da] ?? <Spinner size='small' />}</span>
                                </div>

                                <div className='da-property'>
                                    <span className='property-name'>Throughput</span>
                                    <span className='property-value'>{DA_INFO_MAP[da].throughput || '-'}</span>
                                </div>

                                <div className='da-property'>
                                    <span className='property-name'>Data Retention</span>
                                    <span className='property-value'>{DA_INFO_MAP[da].dataRetention || '-'}</span>
                                </div>

                                <div className='da-property'>
                                    <span className='property-name'>Finality Time</span>
                                    <span className='property-value'>{DA_INFO_MAP[da].finalityTime || '-'}</span>
                                </div>
                            </div>
                        </Button>
                    ))}
                </div>
            </DialogContent>

            <DialogAction secondary close>Cancel</DialogAction>
            <DialogAction primary disabled={!selectedDa} onClick={onContinue} trackEvent='da_confirm_click'>Continue</DialogAction>
        </Dialog>
    );
};

export default DaSelectorDialog;
