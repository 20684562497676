import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';
import useVisibility from '../../shared/hooks/use-visibility';
import { Params } from '../client/station-clients/dymension/generated/rollapp/params';
import { ChannelNetworkMap, EibcRollappLiquidity, Network, VirtualFrontierContract } from './network-types';
import { readStream } from '../../shared/utils/file-utils';
import { CoinsAmount, Currency, NetworkDenom } from '../currency/currency-types';
import { getNetworkData } from './network-service';
import { getMainCurrency } from '../currency/currency-service';

interface NetworkContextValue {
    networks: Network[];
    rollapps: Network[];
    commonNetworks: Network[];
    hubNetwork?: Network;
    rollAppParams?: Params;
    eibcLiquidity?: EibcRollappLiquidity[];
    networkDenoms: NetworkDenom[] | undefined;
    vfcMap?: { [denom: string]: VirtualFrontierContract };
    hubChannelNetworkMap: ChannelNetworkMap;
    hubCurrency?: Currency;
    getNetwork: (networkId: string, includedRegistered?: boolean) => Network | undefined;
    toHubCoins: (coins: CoinsAmount) => CoinsAmount;
    loading: boolean;
    eibcLiquidityLoading: boolean;
    vfcsLoading: boolean;
    rollAppParamsLoading: boolean;
    refreshRollapp: (rollappId: string, extraData?: any) => void;
}

export const NetworkContext = createContext<NetworkContextValue>({} as NetworkContextValue);

export const useNetwork = (): NetworkContextValue => useContext(NetworkContext);

export const NetworkContextProvider = ({ children }: { children: ReactNode }) => {
    const [ networks, setNetworks ] = useState<Network[]>([]);
    const [ networkDenoms, setNetworkDenoms ] = useState<NetworkDenom[]>();
    const [ vfcMap, setVfcMap ] = useState<{ [denom: string]: VirtualFrontierContract }>();
    const [ eibcLiquidity, setEibcLiquidity ] = useState<EibcRollappLiquidity[]>();
    const [ vfcsLoading, setVfcsLoading ] = useState(true);
    const [ loading, setLoading ] = useState(true);
    const [ rollAppParams, setRollAppParams ] = useState<Params>();
    const [ rollAppParamsLoading, setRollAppParamsLoading ] = useState(true);
    const [ toLoadRollapps, setToLoadRollapps ] = useState(true);
    const [ eibcLiquidityLoading, setEibcLiquidityLoading ] = useState(false);
    const visibility = useVisibility();
    const cancelAndSetNetworksPromise = useCancelablePromise<Network[]>();
    const cancelAndSetRollappsPromise = useCancelablePromise<Network[]>();
    const cancelAndSetVfcsPromise = useCancelablePromise<VirtualFrontierContract[]>();
    const cancelAndSetRollappParamsPromise = useCancelablePromise<Params>();
    const cancelAndSetNetworkDenomsPromise = useCancelablePromise<NetworkDenom[]>();
    const cancelAndSetEibcLiquidityPromise = useCancelablePromise<EibcRollappLiquidity[]>();

    const rollapps = useMemo(() => networks.filter((network) => network.type === 'RollApp' &&
        network.currencies.some((currency) => Boolean(currency.baseDenom))), [ networks ]);

    const hubNetwork = useMemo(() => networks.find((network) => network.type === 'Hub'), [ networks ]);

    const getNetwork = useCallback((networkId: string, includedWithoutTokens?: boolean) =>
        networks.find((network) => network.chainId === networkId &&
            (includedWithoutTokens || network.currencies.some((currency) => Boolean(currency.baseDenom)))), [ networks ]);

    const hubCurrency = useMemo(() => hubNetwork && getMainCurrency(hubNetwork), [ hubNetwork ]);

    const commonNetworks = useMemo(() => networks.filter((network) => network.common && !network.hidden), [ networks ]);

    const hubChannelNetworkMap = useMemo((): ChannelNetworkMap => networks.reduce((current, network) =>
        !network.ibc?.hubChannel ? current : ({ ...current, [network.ibc.hubChannel]: network }), {}), [ networks ]);

    const toHubCoins = useCallback((coins: CoinsAmount): CoinsAmount => {
        if (coins.networkId === hubNetwork?.chainId) {
            return { ...coins, ibc: coins.currency.baseDenom === hubCurrency?.baseDenom ? undefined : coins.ibc };
        }
        const networkDenom = networkDenoms?.find((networkDenom) =>
            networkDenom.baseDenom === coins.currency.baseDenom && networkDenom.ibcNetworkId === coins.networkId);

        if (!networkDenom || networkDenom.path === coins.ibc?.path) {
            return coins;
        }
        return { ...coins, ibc: !networkDenom ? undefined : { representation: networkDenom?.denom || '', path: networkDenom?.path || '' } };
    }, [ hubCurrency?.baseDenom, hubNetwork?.chainId, networkDenoms ]);

    const getFixedCurrencies = useCallback((onChainRollapp: Network, network: Network) => {
        return onChainRollapp.currencies.map((onChainRollappCurrency) => {
            const currency = network.currencies.find((currency) => currency.baseDenom === onChainRollappCurrency.baseDenom);
            return { ...onChainRollappCurrency, ...currency };
        });
    }, []);

    useEffect(() => {
        if (visibility) {
            setToLoadRollapps(true);
        }
    }, [ visibility ]);

    useEffect(() => {
        if (!toLoadRollapps) {
            return;
        }
        setToLoadRollapps(false);
        const chainRegistryNetworksPromise =
            cancelAndSetNetworksPromise((signal) =>
                fetch(`${process.env.REACT_APP_GET_NETWORKS_URL}?staging=${process.env.REACT_APP_ENV === 'mainnet-staging'}`, { signal })
                    .then((response) => response?.body ? readStream(response.body).catch(() => '') : undefined)
                    .then((responseText) => JSON.parse(responseText || '{}') as Network[]));
        const onChainRollappsPromise =
            cancelAndSetRollappsPromise((signal) => getNetworkData<Network[]>(process.env.REACT_APP_CHAIN_ID, 'rollapps', false, signal));
        Promise.all([ chainRegistryNetworksPromise, onChainRollappsPromise ])
            .then(([ networks, onChainRollapps ]) => {
                onChainRollapps.filter((rollapp) => rollapp.chainId).forEach((rollapp) => {
                    const networkIndex = networks.findIndex((network) => network.chainId === rollapp.chainId);
                    const network = networks[networkIndex];
                    if (networkIndex >= 0) {
                        networks[networkIndex] =
                            { ...rollapp, ...network, currencies: getFixedCurrencies(rollapp, network), partial: false };
                    } else {
                        networks.push(rollapp);
                    }
                });
                const hiddenNetworkIds = process.env.REACT_APP_HIDDEN_NETWORK_IDS?.split(',') || [];
                return networks
                    .filter((network) => !network.partial && !hiddenNetworkIds.includes(network.chainId))
                    .sort((network1, network2) => {
                        if (network1.type !== 'RollApp' || network2.type !== 'RollApp') {
                            return (network2.type === 'Hub' ? 2 : network2.type === 'RollApp' ? 1 : 0) -
                                (network1.type === 'Hub' ? 2 : network1.type === 'RollApp' ? 1 : 0);
                        }
                        const statusValue1 =
                            network1.status === 'Active' || network1.status === 'Degraded' ||
                            network1.status === 'Unavailable' || network1.status === 'IRO' ? 1 : 0;
                        const statusValue2 =
                            network2.status === 'Active' || network2.status === 'Degraded' ||
                            network2.status === 'Unavailable' || network2.status === 'IRO' ? 1 : 0;
                        if (statusValue1 !== statusValue2) {
                            return statusValue2 - statusValue1;
                        }
                        const rollapp1Sponsored = network1.totalSponsored?.value.power || 0;
                        const rollapp2Sponsored = network2.totalSponsored?.value.power || 0;
                        return rollapp2Sponsored - rollapp1Sponsored;
                    });
            })
            .then(setNetworks)
            .finally(() => setTimeout(() => setLoading(false), 50));
    }, [ cancelAndSetNetworksPromise, cancelAndSetRollappsPromise, toLoadRollapps, getFixedCurrencies ]);

    useEffect(() => {
        if (!hubNetwork) {
            return;
        }
        cancelAndSetNetworkDenomsPromise((signal) => fetch(
            `${process.env.REACT_APP_FETCH_NETWORK_DENOMS_URL}?networkId=${hubNetwork.chainId}`,
            { signal },
        )
            .then((response) => response?.body ? readStream(response.body).catch(() => '') : undefined)
            .then((responseText) => JSON.parse(responseText || '[]') as NetworkDenom[]))
            .then(setNetworkDenoms);
    }, [ cancelAndSetNetworkDenomsPromise, hubNetwork ]);

    useEffect(() => {
        if (!hubNetwork) {
            return;
        }
        setVfcsLoading(true);
        cancelAndSetVfcsPromise((signal) => getNetworkData<VirtualFrontierContract[]>(hubNetwork.chainId, 'vfcs', false, signal))
            .then((vfcs) =>
                setVfcMap(vfcs.filter((vfc) => vfc.enabled).reduce((current, vfc) => ({ ...current, [vfc.minDenom]: vfc }), {})))
            .finally(() => setVfcsLoading(false));
    }, [ cancelAndSetVfcsPromise, hubNetwork ]);

    useEffect(() => {
        if (!hubNetwork) {
            return;
        }
        setEibcLiquidityLoading(true);
        cancelAndSetEibcLiquidityPromise((signal) =>
            getNetworkData<EibcRollappLiquidity[]>(hubNetwork.chainId, 'eibc-liquidity', false, signal))
            .then(setEibcLiquidity)
            .finally(() => setEibcLiquidityLoading(false));
    }, [ cancelAndSetEibcLiquidityPromise, hubNetwork ]);

    useEffect(() => {
        if (!hubNetwork) {
            return;
        }
        setRollAppParamsLoading(true);
        cancelAndSetRollappParamsPromise((signal) => getNetworkData<Params>(hubNetwork.chainId, 'rollapp-params', true, signal))
            .then(setRollAppParams)
            .finally(() => setRollAppParamsLoading(false));
    }, [ cancelAndSetRollappParamsPromise, hubNetwork ]);

    const refreshRollapp = useCallback((rollappId: string, extraData?: any) => setTimeout(() => {
        fetch(process.env.REACT_APP_UPDATE_ROLLAPP_URL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: { rollappId, extraData } }),
        }).then(() => setToLoadRollapps(true));
    }), []);

    return (
        <NetworkContext.Provider
            value={{
                networks,
                toHubCoins,
                commonNetworks,
                rollapps,
                eibcLiquidity,
                networkDenoms,
                rollAppParams,
                hubChannelNetworkMap,
                hubNetwork,
                hubCurrency,
                vfcMap,
                vfcsLoading,
                rollAppParamsLoading,
                eibcLiquidityLoading,
                loading,
                getNetwork,
                refreshRollapp,
            }}
        >
            {children}
        </NetworkContext.Provider>
    );
};
